import api from "../../../service/api";

const searchPatientStatement = (praticeId, patientId) => {
  const path =
    "patient/patient-statement-detail/?patient_pk=" +
    patientId +
    "&practice_pk=" +
    praticeId;
    return api.get(path);
};

const setStatementConfig = (body) => {
  const path = `practice/statement-configuration/`;
  return api.postUpload(path, body);
};

const fetchStatementConfig = (patientId, practiceId,statementQuery) => {
  const path = `patient/patient-statement-preview/?patient_pk=${patientId}&practice_pk=${practiceId}${statementQuery}`;
  return api.get(path);
};

const getStatementConfig = (practicePK) => {
  const path = `/practice/statement-configuration/?practice_pk=${practicePK}`;
  return api.get(path);
};

const updateStatementConfig = (configId, body) => {
  const path = `/practice/statement-configuration-detail/${configId}`;
  return api.put(path, body);
};

const printPatientStatement = async (patientPKs, practicePK) => {
  let path = "patient/print-patient-statement/?patient_pks=" + patientPKs + '&practice_pk=' + practicePK;
  return api.get(path);
}

const ListClaimsDropDown = async (queryString, patient_pk, practice_pk, page = 0) => {
  const encodedQuery = encodeURIComponent(queryString);
  const path = `claim/claims/?drop_down=true&search=${encodedQuery}&page=${page}&filter=1&patient_pk=${patient_pk}&practice_pk=${practice_pk}`;

  return await api.get(path);
};

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    return new Promise((resolve) => {
      timeoutId = setTimeout(() => {
        resolve(func(...args));
      }, delay);
    });
  };
};

// Debounced version of ListClaimsDropDown
const debouncedListClaimsDropDown = debounce(ListClaimsDropDown, 300);

/**
 * Fetches statement release tracker data based on provided filters
 * @param {Object} filters - The filters to apply to the request
 * @param {string} filters.practice_pk - The practice primary key
 * @param {number} filters.page - The page number
 * @param {number} filters.page_size - The number of items per page
 * @param {string} [filters.release_date] - Optional release date filter
 * @param {string} [filters.batch_id] - Optional batch ID filter
 * @param {string} [filters.patient_pk] - Optional patient primary key filter
 * @param {string} [filters.claim_pk] - Optional claim primary key filter
 * @param {string} [filters.statement_vendor] - Optional statement vendor filter
 * @returns {Promise} A promise that resolves with the API response
 */
const getStatementReleaseTrackerData = (filters) => {
  const {
    practice_pk,
    page,
    page_size,
    release_date,
    batch_id,
    patient_pk,
    claim_pk,
    statement_vendor,
    status
  } = filters;

  const params = new URLSearchParams({
    practice_pk,
    page: page.toString(),
    page_size: page_size.toString(),
    list_type: "list",
    ...(status && { status }),
    ...(release_date && { release_date }),
    ...(batch_id && { batch_id }),
    ...(patient_pk && { patient_pk }),
    ...(claim_pk && { claim_pk }),
    ...(statement_vendor && { statement_vendor }),
  });

  return api.get(`patient/get-statement-release-data/?${params.toString()}`);
};



const getStatementBatchFileUrl = async (reqData) => {
  return await api.post("patient/get-statement-batch-file-uri/", reqData)
}


export default {
  searchPatientStatement,
  setStatementConfig,
  fetchStatementConfig,
  getStatementConfig,
  updateStatementConfig,
  printPatientStatement,
  ListClaimsDropDown,
  debouncedListClaimsDropDown,
  getStatementReleaseTrackerData,
  getStatementBatchFileUrl
};
