import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useCallback, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import { CloseSharp } from "@material-ui/icons";

import i18n from "../../../utilities/i18n";
import { checkPermission } from "../../../utilities/commonUtilities";
import { TAB1, TAB2, TAB3, TAB4 } from "../../../utilities/staticConfigs";
import { permission_key_values_patient } from "../../../utilities/permissions";

import ImportPatientList from "./ImportPatientList";
import PatientsStatementManagement from "./PatientsStatementManagement";
import StatementConfigurationView from "./StatementConfiguration/StatementConfigurationView";
import { useSelector } from "react-redux";

import { getStorage } from "../../../utilities/browserStorage";

import Notify from "../../commons/notify";
import LoadingContext from "../../../container/loadingContext";

import service from "../StatementManagement/Services";
import StatementReleaseTracker from "./StatementReleaseTracker";


const CustomIndicator = styled('span')(() => ({
  backgroundColor: 'white',
  height: 7,
  position: 'absolute',
  bottom: 0,
  width: 'calc(100% - 4px)',
  marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
& .MuiTabs-indicator {
  background-color: transparent !important;
  height: 0px !important;
}
`;

const StyledTab = styled(Tab)`
  &.tabMainMenu {
    min-height: 0 !important;
  }
`;

function StatementManagement() {
  //--------- Alert Messages States Start-------------
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");
  //--------- Alert Messages States End-------------

  //With useSelector hook, fetching the data from the store.
  const activeTab = useSelector((state) => state.statementMgData.commons.activeTab);
  const openedPatientTabs = useSelector((state) => state.statementMgData.commons.openedPatientTabs);
  const previewAllTabItems = useSelector((state) => state.statementMgData.commons.previewAllTabItems);
  const previewAllQuery = useSelector((state) => state.statementMgData.commons.previewAllQuery);
  const statementQueries = useSelector((state) => state.statementMgData.commons.statementQueries);

  const practicePK = getStorage("practice");
  const setShowLoadingBar = useContext(LoadingContext);

  // eslint-disable-next-line no-undef
  let fileDownload = require('js-file-download');

  const dispatch = useDispatch();

  function onTabChange(e, tab) {
    dispatch({ type: 'statementMgmnt/updateFieldValues', payload: { state: 'commons', field: 'activeTab', value: tab }, })
  }

  const showNotifyWindow = useCallback((action, type, desc, age = 3000) => {
    if (action === "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }, []);

  const OnPreviewClick = (item, query) => {
    if (item.length === 1) {
      const isTabAlreadyOpen = openedPatientTabs.some(tab => tab.id === item[0].id);

      if (isTabAlreadyOpen) {
        dispatch({
          type: 'statementMgmnt/updateFieldValues',
          payload: {
            state: 'commons',
            field: 'activeTab',
            value: item[0].id

          },
        });

      } else if (openedPatientTabs?.length <= 4) {
        dispatch({
          type: 'statementMgmnt/updateFieldValues',
          payload: {
            state: 'mainState',
            field: 'commons',
            value: {
              activeTab: item[0].id,
              openedPatientTabs: [...openedPatientTabs, { ...item[0], statementQuery: query }],
              previewAllTabItems: previewAllTabItems,
              previewAllQuery: previewAllQuery,
              statementQueries: statementQueries // setting query params
            }
          },
        });
      } else {
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.max_tabs_open_Patient_mgmnt")
        );
      }
    } else if (item.length > 1) { // condition for preview all
      dispatch({
        type: 'statementMgmnt/updateFieldValues',
        payload: {
          state: 'mainState',
          field: 'commons',
          value: {
            activeTab: 'previewAll',
            openedPatientTabs: openedPatientTabs,
            previewAllTabItems: item,
            previewAllQuery: query,
            statementQueries: statementQueries
          }
        },
      });
    }

  };

  const OnCloseTab = (item) => {
    setTimeout(() => {
      dispatch({
        type: 'statementMgmnt/updateFieldValues',
        payload: {
          state: 'mainState',
          field: 'commons',
          value: {
            activeTab: TAB1,
            openedPatientTabs: openedPatientTabs.filter((tabListItem) => tabListItem.id !== item?.id),
            previewAllTabItems: item === 'previewAll' ? [] : previewAllTabItems,
            previewAllQuery: previewAllQuery,
            statementQueries: statementQueries // setting query params.
          }
        },
      });
    }, 100);
  };

  const closeOnError = (patientTabId) => {
    dispatch({
      type: 'statementMgmnt/updateFieldValues',
      payload: {
        state: 'mainState',
        field: 'commons',
        value: {
          activeTab: TAB1,
          openedPatientTabs: openedPatientTabs.filter((tabListItem) => tabListItem.id !== patientTabId),
          previewAllTabItems: previewAllTabItems,
          previewAllQuery: previewAllQuery,
          statementQueries: statementQueries // setting query params
        }
      },
    });
    showNotifyWindow(
      "show",
      "error",
      i18n.t("errorMessages.api_error_patient_mgmnt")
    );
  }

  function onPrintSelected(patientIds) {
    setShowLoadingBar(true);
    const result = service.printPatientStatement(
      patientIds,
      practicePK
    );
    result.then((response) => {
      if (response.data) {
        fileDownload(response.data, response.headers['content-disposition']);
        setShowLoadingBar(false);
      } else {
        throw new Error('Unexpected error occured.');

      }
    }).catch(() => {
      setShowLoadingBar(false)
      showNotifyWindow('show', 'error', i18n.t('errorMessages.commonError'));

    });
  }

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="col-md-12">
        <div className="box">
          <Form id="form_patient_info" autoComplete="off" className="margin-right15">
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabList
                  TabIndicatorProps={{
                      children: <CustomIndicator />
                  }}
                  onChange={onTabChange}
                  scrollable
                  scrollButtons="auto"
                  aria-label="lab API tabs example"
                  variant="scrollable"
                >
                {/* <TabList
                  onChange={onTabChange}
                  aria-label="lab API tabs example"
                > */}
                  <Tab
                    label={i18n.t("statementManagement.statementManagement")}
                    value={TAB1}
                    className="tabMainMenu"
                  />
                  {
                    checkPermission(permission_key_values_patient.statement_management_view) &&
                    <Tab
                      label={i18n.t("statementManagement.importPatientList")}
                      value={TAB2}
                      className="tabMainMenu"
                    />
                  }
                  <Tab
                    label={i18n.t("statementManagement.statementConfiguration")}
                    value={TAB3}
                    className="tabMainMenu"
                  />

                  {
                    openedPatientTabs?.length > 0 && (
                      openedPatientTabs.map((item) => (
                        <StyledTab
                          key={`${item.id}-tab`}
                          label={`${item.patient_name} (${item.custom_patient_id})`}
                          value={item.id}
                          className="tabMainMenu"
                          icon={<CloseSharp style={{ padding: 0, margin: 0 }} onClick={() => { OnCloseTab(item) }} />}
                          iconPosition={"end"}
                        />
                      ))
                    )
                  }
                  {
                    previewAllTabItems?.length > 0 && (
                      <StyledTab
                        key={`preview-All-Tab`}
                        label={`Preview All`}
                        value={'previewAll'}
                        className="tabMainMenu"
                        icon={<CloseSharp style={{ padding: 0, margin: 0 }} onClick={() => { OnCloseTab('previewAll') }} />}
                        iconPosition={"end"}
                      />
                    )
                  }

                  <StyledTab
                    key={`statement-release-tracker`}
                    label={`Statement Tracker`}
                    value={TAB4}
                    className="tabMainMenu"
                  />


                {/* </TabList> */}
                </StyledTabList>
              </Box>
              <TabPanel value={TAB1} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <PatientsStatementManagement OnPreview={OnPreviewClick} />
                </div>
              </TabPanel>
              <TabPanel value={TAB2} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <ImportPatientList OnPreview={OnPreviewClick}/>
                </div>
              </TabPanel>
              <TabPanel value={TAB3} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <StatementConfigurationView
                    edit={true}
                    OnNotify={showNotifyWindow}
                  />
                </div>
              </TabPanel>

              <TabPanel value={TAB4} className="searchBoxTab box-content-white border-radius-topleft-0">
                <div className="padding-top4">
                  <StatementReleaseTracker
                    showNotifyWindow={showNotifyWindow}
                    setShowLoadingBar={setShowLoadingBar}
                    practicePK={practicePK}
                  />
                </div>
              </TabPanel>

              {
                openedPatientTabs?.length > 0 && (
                  openedPatientTabs.map((item) => (
                    <TabPanel
                      key={`${item.id}-tab`}
                      value={item.id}
                      className="searchBoxTab box-content-white border-radius-topleft-0">
                      <div style={{ textAlign: 'right', marginRight: 30, marginBottom: '5px' }}>
                        <button
                          type="button"
                          className="btn btn-primary-blue"
                          onClick={() => { onPrintSelected([item.id]) }}

                        >
                          {i18n.t("statementManagement.prnt")}
                        </button>
                      </div>
                      <div className="padding-top4">
                        <StatementConfigurationView
                          edit={false}
                          patientId={item.id}
                          statementQuery={item.statementQuery}
                          closeOnError={closeOnError}
                        />
                      </div>
                    </TabPanel>
                  ))
                )
              }

              {activeTab === 'previewAll' && previewAllTabItems?.map((item, index) => (
                <TabPanel
                  key={`preview-All-Tab-${item.id}`}
                  value={activeTab}
                  className="searchBoxTab box-content-white border-radius-topleft-0"
                >
                  <>
                    {
                      index === 0 && // Render the PrintAll Btn on the first item
                      <div style={{ textAlign: 'right', marginRight: 30, marginBottom: '5px' }}>
                        <button
                          type="button"
                          className="btn btn-primary-blue"
                          onClick={() => { onPrintSelected(previewAllTabItems.map(item => item.id)) }}
                          >
                            {i18n.t("statementManagement.prntAll")}
                          </button>
                        </div>
                    }
                  <div className="padding-top4">
                    <StatementConfigurationView
                      edit={false}
                      patientId={item.id}
                      statementQuery={previewAllQuery}
                      closeOnError={closeOnError}
                    />
                  </div>
                  </>
                </TabPanel>
              ))}



            </TabContext>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StatementManagement;
