import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../Services";  // Import the service
import { PRINT_SELECTED_OPTION } from "../../../../utilities/dictionaryConstants";
import claimService from '../../../ClaimsModule/SearchClaims/service';
import { getStorage } from "../../../../utilities/browserStorage"
import { DEFAULT_PAGING_SIZE, PATIENT_STATEMENT_STATUSES, PAGING_END_INDEX, PATIENT_STATEMENT_VENDORS } from "../../../../utilities/staticConfigs";


export const initialStatementReleaseTrackerState = {
    filters: {
        status: "",
        release_date: "",
        batch_id: "",
        patient_selected: [],
        patient_pk: "",
        claim_pk: "",
        patientAdvSearchData: [],
        selected_claim: [],
        statement_vendor: PATIENT_STATEMENT_VENDORS.find(item => item.name === "DMA")?.id,
    },
    tableData: [],
    pagination: {
        startIndex: 0,
        endIndex: PAGING_END_INDEX,
        page_size: DEFAULT_PAGING_SIZE,
        page: 1,
        total_pages: 0,
    },
    storedFiltersForPagination: {},
    apiState: {
        isLoading: false,
        isError: false,
        message: ""
    }
};

const initialState = {
    commons: {
        activeTab: 'tab1',
        openedPatientTabs: [],
        previewAllTabItems: [],
        previewAllQuery: '',
        statementQueries: ''
    },
    tab1StatementManagment: {
        statementManagementState: {
            "dosFrom": "",
            "dosTo": "",
            "claimEnteredFrom": "",
            "claimEnteredTo": "",
            "balanceType": "",
            "selectInsurance": [],
            "noStatementSent": "",
            "lastStatement": "",
            "blnce1": "",
            "blnce2": "",
            "selectProvider": [],
            "selectFacility": [],
            "serviceLocation": [],
            "serviceLocationType": [],
            "billPR": [],
            "clmStatus": [],
            "printSelectedOption": PRINT_SELECTED_OPTION[0]["id"],
            "patientSelectedArray": [],
            "patient_pk":""
        },
        tableData: [],
        count: 0,
        pageSize: 0,
        currentPage: 1,
        hasSelectedAll: false,
        selectedEntries: []
    },
    tab2ImportPatient: {
        importpatientStateData: {
            "selectPatientDropdown": 1,
            "dosFirst": "",
            "dosSecond": "",
            "claimDate": "",
            "claimDate2": "",
            "balanceType": "",
            "noStatementSent": "",
            "lastStatement": "",
            "selectInsurance": [],
            "selectFacility": [],
            "serviceLocation": [],
            "blnce1": "",
            "blnce2": "",
            "selectProvider": [],
            "billPR": [],
            "clmStatus": [],
            "patientList": [],
            "numberOfStatements": 1
        },
        importPatientTableData: [],
    },
    statementReleaseTrackerTabData: initialStatementReleaseTrackerState,
    dropDownListData: {
        isLoading: false,
        isErrorGettingDropdownData: false,
        isFilterDropDownDataReceived: false,
        InsuranceNameList: [],
        ProviderList: [],
        FacilityList: [],
        BillPRList: [],
        ClaimStatusList: [],
    },
    loading: false,
    errorState:'',
    resetFlag:false,
};

/**
 * calling first tab api call on run report button click
 */
export const getTableData = createAsyncThunk(
    "statement/statementManagement",
    async (data) => {
        const result = await service.ListPatientsStatements(data.query, data.pageSize, data.type, data.practicePK);
        if (result) {
            return { data: result.data, filter: data.filterData, query: data.query };
        }
    }
);

/**
 * calling second tab import patient on run report api call
 */
export const getImportPatientTableData = createAsyncThunk(
    "statement/getImportPatientTableData",
    async (data, thunkAPI) => {
        try {
            const result = await service.ListPatientsStatements(data.query, data.pageSize, data.page, data.practicePK);
            if(result.status === 400 || result.status === 404 ){
                return thunkAPI.rejectWithValue(result.statusText); 
            } else {
                return { data: result.data, filter: data.filterData };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message); 
        }
    }
);

/*
 * Function to get call the api for all the dropdown list data 
 */
export const getDropDownListData = createAsyncThunk(
    "statementManagment/getDropDownListData",
    async (payload, thunkAPI) => {
        const pageSize = 0;
        const page = 0;
        const end_index = 10;
        const pageNum = 1
        const practicePK = getStorage('practice');
        /**
         * The promises array contains the promises returned by each API call, 
         * which can be resolved using Promise.all(promises) to wait for all the requests to complete.
         */
        const promises = [
            service.ListInsuranceNames(pageSize, page, practicePK).then(response => response.data),
            service.ListRenderingProviders(end_index, pageNum, practicePK).then(response => response.data.results),
            claimService.ServiceLocationLists(practicePK).then(response => {
                let locations = []
                response.data.map((item) => {
                    item.id = item.Id + '||' + item.location_type
                    locations.push(item)
                })
                return locations
            }),
            service.GetBillByPRCode(pageSize, page, practicePK, 'PR').then(response => response.data.results),
            claimService.ListClaimStatus(
                pageSize,
                page,
                practicePK,
                null
            ).then(response => response.data)
        ];

        try {
            const [
                InsuranceNameList,
                ProviderList,
                FacilityList,
                BillPRList,
                ClaimStatusList,
            ] = await Promise.all(promises);

            return {
                InsuranceNameList,
                ProviderList,
                FacilityList,
                BillPRList,
                ClaimStatusList
            };
        } catch (error) {
            // Handle error
            return thunkAPI.rejectWithValue({ errorMessage: 'Error getting Drop-down list data!' })
        }
    }
);


export const getStatementReleaseTrackerData = createAsyncThunk(
    "statementManagment/getStatementReleaseTrackerData",
    async (payload, thunkAPI) => {
        const { filters, practicePK, pagination } = payload;

        try {
            const response = await service.getStatementReleaseTrackerData({
                ...filters,
                practice_pk: practicePK,
                page: pagination.page,
                page_size: pagination.page_size
            });

            if (response.status === 200) {
                return {
                    data: response.data,
                    pagination: pagination
                }; // Return data directly if status is 200
            } else {
                return thunkAPI.rejectWithValue("Failed to fetch statement release tracker data");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.message || error.message || "An error occurred while fetching data");
        }
    }
);

/**
 * statement management slice
 */
export const statementManagementSlice = createSlice({
    name: 'statementMgmnt',
    initialState,
    reducers: {
        setStateData: (state, actions) => {
            let data = {
                ...state.tab1StatementManagment.statementManagementState,
                [actions.payload.node]: actions.payload.value
            }
            state.tab1StatementManagment.statementManagementState = data
        },

        setPatientPKData: (state, actions) => {
            let data = {
                ...state.tab1StatementManagment.statementManagementState,
                [actions.payload.node]: actions.payload.value
            }
            state.tab1StatementManagment.statementManagementState = data
        },
        setImportStateData: (state, actions) => {
            let data = {
                ...state.tab2ImportPatient.importpatientStateData,
                [actions.payload.node]: actions.payload.value
            }
            state.tab2ImportPatient.importpatientStateData = data
           
        },
        onTab1StateReset: (state) => {
            state.tab1StatementManagment.statementManagementState =
                initialState.tab1StatementManagment.statementManagementState
                state.tab1StatementManagment.tableData=[]
                state.tab1StatementManagment.count= 0,
                state.tab1StatementManagment.pageSize= 0,
                state.tab1StatementManagment.currentPage =1
                state.resetFlag=true
                
        },
        onTab2StateReset: (state) => {
            state.tab2ImportPatient.importpatientStateData =
                initialState.tab2ImportPatient.importpatientStateData
                state.resetFlag=true
              
                
        },
        // Accepts the name of the state, the field, and the value to handle value change in tickets add or edit modal
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
        resetData: () => initialState,
        resetStatementReleaseTrackerData: (state) => {
            state.statementReleaseTrackerTabData = initialStatementReleaseTrackerState;
        },
    },

    extraReducers: (builder) => {

        builder.addCase(getTableData.pending, (state) => {
            state.resetFlag=false;
            state.loading = true;
        });
        builder.addCase(getTableData.fulfilled, (state, action) => {
            // Before setting the response table data to the state, lets first check if this item was previously user checked while page change
            state.tab1StatementManagment.tableData = action.payload.data.results.map((item) => {
                const isChecked = state.tab1StatementManagment.selectedEntries.includes(item.id);
                return { ...item, isChecked };
            });

            state.tab1StatementManagment.count = action.payload.data.count;
            state.tab1StatementManagment.pageSize = action.payload.data.page_size;
            state.tab1StatementManagment.currentPage = action.payload.data.links.current_page;
            state.commons.statementQueries = action.payload.query;
            state.loading = false;
            state.resetFlag=false;
        });
        builder.addCase(getTableData.rejected, (state) => {
            state.loading = false;
            state.resetFlag=false;
        });
        builder.addCase(getImportPatientTableData.pending, (state) => {
            state.resetFlag=false;
            state.loading = true;
          
           
          
        });
        builder.addCase(getImportPatientTableData.fulfilled, (state, action) => {
            state.tab2ImportPatient.importPatientTableData = action.payload.data
            state.resetFlag=false;
            state.loading = false;
           
          
         
        });
        builder.addCase(getImportPatientTableData.rejected, (state,action) => {
            state.loading = false;
            state.resetFlag=false;
            state.errorState = action.payload
           
           
        });
        builder
            .addCase(getDropDownListData.pending, (state) => {
                state.dropDownListData.isLoading = true;
                state.dropDownListData.isErrorGettingDropdownData = false;
            })
            .addCase(getDropDownListData.fulfilled, (state, action) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = false;
                state.dropDownListData.isFilterDropDownDataReceived = true
                const {
                    InsuranceNameList,
                    ProviderList,
                    FacilityList,
                    BillPRList,
                    ClaimStatusList
                } = action.payload;
                state.dropDownListData.InsuranceNameList = InsuranceNameList;
                state.dropDownListData.ProviderList = ProviderList;
                state.dropDownListData.FacilityList = FacilityList;
                state.dropDownListData.BillPRList = BillPRList;
                state.dropDownListData.ClaimStatusList = ClaimStatusList;
            })
            .addCase(getDropDownListData.rejected, (state) => {
                state.dropDownListData.isLoading = false;
                state.dropDownListData.isErrorGettingDropdownData = true;
            });
        builder
            .addCase(getStatementReleaseTrackerData.pending, (state) => {
                state.statementReleaseTrackerTabData.apiState.isLoading = true;
            })
            .addCase(getStatementReleaseTrackerData.fulfilled, (state, action) => {
                const { results, links, count, page_size } = action.payload.data || {};
                const { startIndex, endIndex } = action.payload.pagination || {};

                if (Array.isArray(results) && results.length) {
                    const processedTableData = results.map((item) => {
                        const patientName = item.patient?.display_name || "Unknown Patient"; // Fallback for missing patient name
                        const claims = item.claims.map((claim) => ({
                            ...claim,
                            label: claim.custom_claim_id,
                        }));

                        const status = PATIENT_STATEMENT_STATUSES.find(status => status.id === item.status)?.name || item.status;

                        return {
                            ...item,
                            patient_name: patientName,
                            claims,
                            status,
                        };
                    });

                    state.statementReleaseTrackerTabData.tableData = processedTableData;
                    state.statementReleaseTrackerTabData.pagination = {
                        ...state.statementReleaseTrackerTabData.pagination,
                        total_pages: Math.ceil(count / page_size) ?? 0,
                        page: links.current_page,
                        startIndex: startIndex ?? state.statementReleaseTrackerTabData.pagination.startIndex,
                        endIndex: endIndex ?? state.statementReleaseTrackerTabData.pagination.startIndex
                    }

                    state.statementReleaseTrackerTabData.apiState = {
                        isLoading: false,
                        isError: false,
                        message: ""
                    }
                } else {
                    // Handle case where results are not an array or are empty
                    state.statementReleaseTrackerTabData.tableData = [];
                    state.statementReleaseTrackerTabData.pagination = {
                        ...state.statementReleaseTrackerTabData.pagination,
                        total_pages: 0,
                        page: 1,
                        startIndex: initialStatementReleaseTrackerState.pagination.startIndex,
                        endIndex: initialStatementReleaseTrackerState.pagination.startIndex
                    };

                    state.statementReleaseTrackerTabData.apiState = {
                        isLoading: false,
                        isError: true,
                        message: "No records found. Please adjust your filters or broaden your search."
                    }
                }
            })
            .addCase(getStatementReleaseTrackerData.rejected, (state, action) => {
                state.loading = false;
                state.errorState = action.payload;
                state.statementReleaseTrackerTabData.tableData = [];
                state.statementReleaseTrackerTabData.pagination = {
                    ...state.statementReleaseTrackerTabData.pagination,
                    total_pages: 0,
                    page: 1,
                    startIndex: initialStatementReleaseTrackerState.pagination.startIndex,
                    endIndex: initialStatementReleaseTrackerState.pagination.startIndex
                };

                state.statementReleaseTrackerTabData.apiState = {
                    isLoading: false,
                    isError: true,
                    message: "An error occurred while processing your request. Please verify your selected filters and try again. If the issue persists, contact support."
                }
            });
    }
})

export const {
    resetData,
    storeFilterAndTable,
    storeImportPatientFilterData,
    setStateData,
    setImportStateData,
    onTab1StateReset,
    onTab2StateReset,
    setPatientPKData,
    updateFieldValues,
    resetStatementReleaseTrackerData,
} = statementManagementSlice.actions

export default statementManagementSlice.reducer;