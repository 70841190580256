const TableHeadData = [
    {
        "name": "Release Date",
        "type": "Date",
        "width": "small",
        "sort": false,
        "needDateFormat": "yes"
    },
    {
        "name": "Patient",
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": "Claims",
        "type": "string",
        "width": "medium",
        "sort": false,
    },
    {
        "name": "Status",
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": "Total Balance Invoiced",
        "type": "currency",
        "width": "small",
        "sort": false,
    },
    {
        "name": "Batch ID",
        "type": "string",
        "width": "small",
        "sort": false,
    },
    {
        "name": "Batch File",
        "type": "string",
        "width": "small",
        "sort": false,
    },
];

export const StatementReleaseTrackerTableBodyData = [
    [
        {
            "id": "",
            "name": "release_date",
            "type": "Date",
            "needDateFormat": "yes",
            "align": "left",
            "value": "",
            "width": "small",
        },
        {
            "id": "",
            "name": "patient_name",
            "value": "",
            "type": "chip",
            "clickable": false,
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "claims",
            "value": "",
            "type": "multiple_chip",
            "clickable": false,
            "align": "left",
            "width": "medium",
        },
        {
            "id": "",
            "name": "status",
            "value": "",
            "type": "chip",
            "clickable": false,
            "align": "left",
            "width": "small",
        },
        {
            "id": "",
            "name": "total_patient_balance_invoiced",
            "value": "",
            "type": "currency",
            "align": "right",
            "width": "small",
        },
        {
            "id": "",
            "name": "batch_id",
            "value": "",
            "type": "string",
            "align": "center",
            "width": "small",
        },
        {
            "id": "",
            "name": "action",
            "type": "button",
            "align": "center",
            "width": "action_column",
            "dropdown": [
                {
                    "id": '',
                    "name": "Download"
                }
            ]
        },
    ]
];

export const StatementReleaseTrackerTableConfig = {
    "name": "statement-release-tracker-table",
    "isResize": false,
    "isFixedHead": true,
    "tableHeadings": TableHeadData,
    "tableBodyData": StatementReleaseTrackerTableBodyData
};